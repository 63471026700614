<template>
  <span v-if="shouldShow" class="label-base bg-red-500 text-white text-xs" @click="() => showAlert()">Niet ontvangen</span>
</template>

<script setup>
import isRitRetourDeel from '@/components/Table/Kalender/isRitRetourDeel'
import { defineProps, computed } from 'vue'

const props = defineProps({
  rit: Object,
})

const now = Date.now()
const shouldShow = computed(() => {
  const rit = props.rit || {}
  return (
    isRitRetourDeel(rit)
    && rit.is_te_ontvangen
    && rit.bedrag_open_saldo > 0
    && new Date(rit.vertrek_datetime || rit.datum_linked) < now
  )
})

const showAlert = () => {
  return alert('Dit is de retour van een reservatie,\nwaarvan de reservatie (nog) niet volledig werd betaald!\nDeze reservatie stond nochtans op "te ontvangen".')
}
</script>
